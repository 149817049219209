// overall stock report
export const carrirngAgentWiseProgramReport = 'fertilizer/reports/movement-report/carrirng-agent-wise-program-report'
export const ProgramNoSerach = 'fertilizer/reports/movement-report/program-search'
// program management
export const portwiseAllocation = '/fertilizer/movement/cnf-agent-appointment/port-allocation-quantity'
export const receivedReport = '/fertilizer/reports/movement-report/receive-entry-lori-challan-report'

export const reportHeadingList = '/fertilizer/config/report-heading/detail'

export const currentStockReport = 'fertilizer/reports/movement-report/current-stock-report'
export const interGodownReceivedReport = '/fertilizer/reports/inter-godown-report/receive-godown-lori-challan-report'
export const programManagementReport = '/fertilizer/reports/movement-report/program-management-report'
export const allocationReport = '/fertilizer/reports/movement-report/allocation-report'
export const movementReport = '/fertilizer/reports/movement-report/transport-agent-wise-allocation-report'

// dealer profile
export const dealerProfileData = '/fertilizer/reports/config-report/dealer-profile-report'

// warehouse report
export const warehouseReportApi = '/fertilizer/reports/warehouse-report/warehouse-report'
export const fertilizerDeliveryReceiveReport = '/fertilizer/reports/movement-report/fertilizer-delivery-receive-report'
export const transportProgramManageReportGodownWise = '/fertilizer/reports/movement-report/transport-program-management-report-region-wise'

export const ministryAllocationApi = 'fertilizer/reports/sales-report/ministry-allocation-report'
// Opening Balance Report
export const openingBalanceReport = '/fertilizer/reports/movement-report/opening-balance-report'
// dealer wise allocation Report
export const dealerAllocationReport = '/fertilizer/reports/dealer-wise-allocation/list'
export const warehouseUserSelectList = '/fertilizer/config/warehouse-user/select-list'
export const demandAgainstAllocationReport = '/fertilizer/reports/sales-report/demand-against-allocation-report'
// stock details report
export const stockDetailsReport = '/fertilizer/reports/stock-report/details'

// daily sales report
export const dailySalesReport = '/fertilizer/reports/sales-report/daily-sales-report'
// imported fertilizer report
export const importedFertilizerReport = '/fertilizer/reports/sales-report/imported-fertilizer-report'
// fertilizer requision report
export const fertilizerRequisitionReport = '/dealer-panel/reports/fertilizer-requisition-report-admin'
