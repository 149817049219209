<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.program_management_report') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
              <b-col lg="6" sm="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="report_type"
                    >
                    <template v-slot:label>
                    {{ $t('fertilizerReport.report_type') }} <span class="text-danger">*</span>
                    </template>
                   <b-form-radio-group
                      v-model="search.report_type"
                      :options="options"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                    ></b-form-radio-group>
                  </b-form-group>
              </b-col>
              <b-col lg="6" sm="12"></b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Program No" vid="program" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="program_name"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('movement.program_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="program_name"
                        v-model="search.program_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :placeholder="$t('movement.program_no')"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col v-if="search.report_type === 1" lg="6" sm="12">
                <ValidationProvider name="Godown Name" vid="godown_infos_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="godown_infos_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('fertilizerReport.godown') }}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.godown_infos_id"
                      :options="godownInfoList"
                      id="godown_infos_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col v-if="search.report_type === 2" lg="6" sm="12">
                <ValidationProvider name="Transport Agent" vid="transport_agent_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="transport_agent_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('movement.transport_agent') }}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.transport_agent_id"
                      :options="transportAgentList"
                      id="transport_agent_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if='datas.length'>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('fertilizerReport.program_management_report') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                        <b-button class="btn_add_new" @click="pdfExport">
                          <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                        </b-button>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                          <b-row mt-5>
                            <b-col md="12" class="table-responsive">
                              <div style="border: 2px solid;margin:10px;">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                      {{ $t('fertilizerReport.program_management_report') }}
                                    </list-report-head>
                                    <div class="text-center">
                                      <p class="text-dark" style="margin:0">{{ $t('movement.program_no') }} : {{ $n(search.program_name) }}</p>
                                      <span v-if="search.report_type === 1 && search.godown_infos_id" class="text-dark">{{ $t('fertilizerReport.godownName') }} : {{ getGodownName(search.godown_infos_id) }}</span>
                                      <span v-if="search.report_type === 2 && search.transport_agent_id" class="text-dark">{{ $t('movement.transport_agent') }} : {{ getAgentName(search.transport_agent_id) }}</span>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <div class="col text-right">
                                    <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                  </div>
                                </b-row>
                                <b-table-simple class="tg mt-3" hover bordered small caption-top>
                                  <b-thead>
                                    <b-tr>
                                      <b-td class="tg-0lax">{{$t('globalTrans.sl_no')}}</b-td>
                                      <b-td class="tg-0lax">{{$t('fertilizerReport.godown')}} </b-td>
                                      <b-td class="tg-0pky">{{$t('movement.transport_agent') }}</b-td>
                                      <b-td class="tg-0lax">{{$t('movement.fertilizer_name') }}</b-td>
                                      <b-td class="tg-0lax" style="text-align: right !important;">{{$t('movement.allocated_amount') }}</b-td>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr  v-for="(report, index1) in datas" :key="index1">
                                      <b-td class="tg-0lax">{{$n(index1+1)}}</b-td>
                                      <b-td class="tg-0lax">{{ ($i18n.locale === 'bn') ? report.godown.name_bn : report.godown.name }}</b-td>
                                      <b-td class="tg-0lax">{{ ($i18n.locale === 'bn') ? report.agent.company_agent_name_bn : report.agent.company_agent_name }}</b-td>
                                      <b-td class="tg-0lax">{{ ($i18n.locale === 'bn') ? report.fertilizer_name_bn : report.fertilizer_name }}</b-td>
                                      <b-td class="tg-0lax" style="text-align: right !important;">{{ $n(report.amount) }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                      <b-th class="tg-0lax" colspan="4" style="text-align: right !important;">{{ $t('fertilizerReport.GrandTotalMTon') }}</b-th>
                                      <b-th class="tg-0lax" style="text-align: right !important;">
                                        {{ $n(calculateTotal(datas)) }}
                                      </b-th>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </div>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, programManagementReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      showData: false,
      search: {
        program_name: '',
        godown_infos_id: 0,
        transport_agent_id: 0,
        report_type: 1
      },
      datas: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    options: function () {
      return [
        { item: 1, name: (this.$i18n.locale === 'bn' ? 'গুদাম ভিত্তিক' : 'Godown Wise') },
        { item: 2, name: (this.$i18n.locale === 'bn' ? 'পরিবহন এজেন্ট ভিত্তিক' : 'Transport Agent Wise') }
      ]
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    flatpickr('.toDate', {})
    core.index()
  },
  methods: {
    calculateTotal (report) {
       return report.map(r => r.amount).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      this.loading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, programManagementReport, this.search).then(response => {
        if (response.success) {
          if (response.data) {
            this.datas = response.data
            this.showData = true
          }
        } else {
          this.datas = []
        }
      })
      this.loading = false
    },
    pdfExport () {
      const programNo = this.search.program_name ?? ''
      let reportType = ''
      let reportTypeTitle = ''
      let cloneType = ''
      if (this.search.godown_infos_id || this.search.transport_agent_id) {
        reportTypeTitle = (this.search.report_type === 1) ? this.$t('movement.godownName') : this.$t('movement.transport_agent')
        reportType = (this.search.report_type === 1) ? this.getGodownName(this.search.godown_infos_id) : this.getAgentName(this.search.transport_agent_id)
        cloneType = reportTypeTitle + ' : ' + reportType
      }
      const reportTitle = this.$t('fertilizerReport.program_management_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.datas, this, programNo, cloneType)
    },
    getGodownName (Id) {
      if (Id) {
        const godown = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(item => item.value === parseInt(Id))
        if (typeof godown === 'undefined') {
          return ''
        } else {
          return godown.text
        }
      }
    },
    getAgentName (Id) {
      const agent = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.value === parseInt(Id))
      if (typeof agent === 'undefined') {
        return ''
      } else {
        return agent.text
      }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
