import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, programNo = '', typeName = '') => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            columns: [
              { width: '*', text: '' },
              { width: '*', text: '' },
              { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
              { width: '*', text: '' },
              { width: '*', text: '' }
            ],
            style: 'krishi'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        if (programNo) {
          pdfContent.push({ text: vm.$t('movement.program_no') + ' : ' + vm.$n(programNo), style: 'address', alignment: 'center' })
        }
        if (typeName) {
          pdfContent.push({ text: typeName, style: 'report_type', alignment: 'center' })
        }
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.godownName'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.transport_agent'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.fertilizer_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.allocated_amount'), style: 'th', alignment: 'right' }
          ]
        ]
        let totalAmt = 0
        data.map((report, index) => {
          allRows.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? report.godown.name_bn : report.godown.name, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? report.agent.company_agent_name_bn : report.agent.company_agent_name, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? report.fertilizer_name_bn : report.fertilizer_name, style: 'td', alignment: 'center' },
            { text: vm.$n(report.amount), style: 'td', alignment: 'right' }
          ])
          totalAmt += parseInt(report.amount)
        })
        allRows.push(
          [
            { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 4, alignment: 'right', bold: true },
            {},
            {},
            {},
            { text: vm.$n(totalAmt), style: 'th', alignment: 'right', bold: true }
          ]
        )
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '25%', '25%', '20%', '20%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 10]
            },
            main_head: {
              fontSize: 22
            },
            header: {
              fontSize: 10,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 14,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            header3: {
              fontSize: 9,
              margin: [0, 0, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            },
            krishi: {
              margin: [0, -5, 0, 15],
              alignment: 'center'
            },
            report_type: {
              fontSize: 9,
              margin: [0, 2, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('program-management-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
